<template>
  <section class="q-pa-md">
    <div class="row justify-center items-center q-gutter-md q-pb-md heading">
      <div class="title">
        <h2 class="text-h4 text-weight-medium no-margin">
          Explore Ways To Search
        </h2>
      </div>
    </div>

    <div class="row justify-evenly items-stretch">
      <q-item
        v-ripple
        class="col-6 col-sm-auto q-pa-lg"
        clickable
        @click="dialog({ component: LazyDialogFirearmFinder })"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiPistol"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Firearm Finder
            </h3>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        to="/brands"
        class="col-6 col-sm-auto q-pa-lg"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiOfficeBuilding"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Brands
            </h3>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        href="https://range.kygunco.com"
        target="_blank"
        class="col-6 col-sm-auto q-pa-lg"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiTarget"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Range
            </h3>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        to="/reviews"
        class="col-6 col-sm-auto q-pa-lg"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiStarShooting"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Customer Reviews
            </h3>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        to="/search?sale=true"
        class="col-6 col-sm-auto q-pa-lg"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiSale"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Sale
            </h3>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        to="/contact"
        class="col-6 col-sm-auto q-pa-lg"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiHeadset"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Support
            </h3>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        to="/blog"
        class="col-6 col-sm-auto q-pa-lg"
      >
        <q-item-section class="column items-center q-gutter-y-sm">
          <q-avatar
            :icon="mdiTextBoxEdit"
            size="125px"
            font-size="80px"
            color="blue-grey-8"
            text-color="white"
          />

          <div class="text-center">
            <h3 class="text-h6 no-margin">
              Blog
            </h3>
          </div>
        </q-item-section>
      </q-item>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {
  mdiHeadset,
  mdiOfficeBuilding,
  mdiPistol,
  mdiSale,
  mdiStarShooting,
  mdiTarget,
  mdiTextBoxEdit,
} from '@quasar/extras/mdi-v7';
import { LazyDialogFirearmFinder } from '#components';

const { dialog } = useQuasar();
</script>
